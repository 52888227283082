import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import audioFile from '../assets/audio.mp3';

import i1 from '../assets/i1.PNG';
import i2 from '../assets/i2.PNG';
import i3 from '../assets/i3.PNG';
import i4 from '../assets/i4.PNG';
import i5 from '../assets/i5.PNG';
import bg1 from '../assets/bg3a.png';
import bg2 from '../assets/bg1a.PNG';
import bg3 from '../assets/bg2b.JPG';
import army from '../assets/header.gif';
import header from '../assets/header2.jpg';


import '../App.css';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // Use useEffect to show the modal when the component mounts
  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <body>
        <div className="container">
          <a href="https://jup.ag/swap/SOL-8NNXWrWVctNw1UFeaBypffimTdcLCcD8XJzHvYsmgwpF" className="jupiter">brrrrrrrrrrrrrrrrrruuy</a>
          <p className="title">BRAINLET</p>
          <p className="ca">$BRAINLET: 8NNXWrWVctNw1UFeaBypffimTdcLCcD8XJzHvYsmgwpF</p>
          <br/>
          <p className="heh">COMNUNMITY TAKEVOER</p>
          {/* <p className="hehe"></p> */}
          {/* <a href="https://www.reddit.com/r/copypasta" className="heheh">r/copypasta</a> */}
          <a href="https://t.me/brainletonsol" className="i1container"><img src={i1} alt="telegram" className='icon'/></a>
          <a href="https://www.dextools.io/app/en/solana/pair-explorer/CW9DFoTWEUiwxyxVGnQFYhbrYEfGkvaqXEgxKZG7d7X1?t=1721441211632" className="i2container"><img src={i2} alt="twitter" className='icon'/></a>
          <a href="https://solscan.io/token/8NNXWrWVctNw1UFeaBypffimTdcLCcD8XJzHvYsmgwpF" className="i3container"><img src={i3} alt="dextools" className='icon'/></a>
          <a href="https://dexscreener.com/solana/cw9dfotweuiwxyxvgnqfyhbryefgkvaqxegxkzg7d7x1" className="i4container"><img src={i4} alt="dexscreener" className='icon'/></a>
          <a href="https://twitter.com/brainletCTO" className="i5container"><img src={i5} alt="solscan" className='icon'/></a>
        </div>
        <div className='container'>
          <a href="https://www.coingecko.com/en/coins/brainlet-2" className="i6container">coimgekco</a>
          <a href="https://coinmarketcap.com/currencies/brainlet/" className="i7container">coimarketap</a>
        </div>
        <p className="exchange">enxanches:</p>
        <div className='container'>
          <a href="https://www.xt.com/en/trade/brainlet_usdt" className="exxt">xt</a>
          <a href="https://www.bitmart.com/trade/en-US?symbol=BRAINLET_USDT" className="exbm">bitmart</a>
          <a href="https://www.superex.com/trade/BRAINLET_USDT" className="exse">superex</a>
          <a href="https://www.mexc.com/exchange/BRAINLET_USDT?_from=market" className="exmc">mexc</a>
          <a href="https://www.ourbit.com/exchange/BRAINLET_USDT?_from=search" className="exob">ourbit</a>
          <a href="https://ascendex.com/en/cashtrade-spottrading/usdt/brainlet" className="exae">ascendex</a>
        </div>
        <p className="card">cerdit card:</p>
        <div className='container'>
          <a href="https://moonshot.money/BRAINLET" className="cams">moonshot</a>
          <a href="https://www.prerich.com/" className="capr">prerich</a>
        </div>
        <img src={header} alt="itsok" className='header'/>
        <img src={army} alt="armygif" className='army'/>
        <img src={bg2} alt="collage1" className='mid'/>
        <img src={bg1} alt="collage3" className='top'/>
        <img src={bg3} alt="collage2" className='bottom'/>
        {/* <iframe src="https://brainletme.vercel.app/" 
          className="iframe" 
          allow="clipboard-write">
        </iframe> */}
        <p className="footer">contact: brainlet33@gmail.com :) welcum home.</p>
        {/* <img src={bg1} alt="collage" className='main'/> */}
      </body>
    </div>
  );
}

export default App;
